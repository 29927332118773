import React from 'react';
import { Grid, Card, Header } from 'semantic-ui-react';
import config from '../../config/config';
import './styles.css';
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

const language = useSelector(getLanguage);
const t = (key) => {
  const languageString = language[0]+language[1];
  const translation = translations[languageString];
  return translation ? translation[key] || key : key;
};

const CardSummary = props => (
  <Card centered className="cart-summary card-summary">
    <Card.Content>
      <Grid>
        <Grid.Row className="card-summary-row">
          <Grid.Column width={8}>{t('orderCost')}</Grid.Column>
          <Grid.Column textAlign="right" width={8}>
            <div dangerouslySetInnerHTML={{ __html: props.total_products + ' ' + config.CURRENCY }} />
          </Grid.Column>
        </Grid.Row>
        { props.show_shipping ?
          <React.Fragment>
            <Grid.Row className="card-summary-row">
              <Grid.Column width={8}>Доставка</Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <div dangerouslySetInnerHTML={{ __html: props.total_shipping + ' ' + config.CURRENCY }} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="card-summary-row total" as={Header}>
              <Grid.Column width={8}>Всего к оплате</Grid.Column>
              <Grid.Column textAlign="right" width={8}>
                <div dangerouslySetInnerHTML={{ __html: (props.total_products + props.total_shipping) + ' ' + config.CURRENCY }} />
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
          : ''}
      </Grid>
    </Card.Content>
  </Card>
);

CardSummary.defaultProps = {
  total_shipping: 0,
  show_shipping: false,
};

export default CardSummary;
