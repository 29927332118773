import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Sidebar, Menu, Icon, Header,
} from 'semantic-ui-react';

import './styles.css';
import {useDispatch, useSelector} from "react-redux";
import {isSearchVisible} from "../../components/NavTopBar/reducer";
import {isMenuVisible} from "../../components/NavBottomBar/reducer";
import {closeSearch} from "../../components/NavTopBar/actions";
import {useMixpanel} from "react-mixpanel-browser";
import {closeMenu} from "../../components/NavBottomBar/actions";
import {dropToken} from "../../components/UserLogin/actions";
import {getUserLoggedIn} from "../../components/UserLogin/reducer";
import {useWindowDimensions} from "../../components/WindowDimensions";
import ExpoLocation from "../../components/ExpoLocation";
import translations from "../../components/LanguageModal/translations";
import {getLanguage} from "../../components/LanguageModal/reducer";


function SideMenu(props) {

  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const menuVisible = useSelector((state) => isMenuVisible(state.navbottombar));
  const isLoggedIn = useSelector((state) => getUserLoggedIn(state.userLogin));

  const { height, width } = useWindowDimensions();
  const visible = useSelector((state) => isMenuVisible(state.navbottombar));
  //console.log(visible)
  const language = useSelector(getLanguage);
  const t = (key) => {
    const languageString = language[0]+language[1];
    const translation = translations[languageString];
    return translation ? translation[key] || key : key;
  };

  useEffect(() => {

    if (searchVisible && menuVisible) {
      dispatch(closeSearch());
    }

    if (mixpanel) mixpanel.track('Side Menu Open', {menuVisible, isLoggedIn});

  }, [menuVisible]);

  return (
    <Sidebar
      as={Menu}
      animation="push"
      width="thin"
      visible={menuVisible}
      icon="labeled"
      inverted
      //color="orange"
      direction="right"
      vertical
      className="menu-side-bar"
    >
      <Header as="h2" inverted>
        {t('menu')}
      </Header>
      <Link to="#">
        <Menu.Item name="locations">
          <Icon name="move" size="small" />
          {width} x {height} px
        </Menu.Item>
      </Link>
      <Link to="#" onClick={()=>dispatch(closeMenu)}>
        <Menu.Item name="sharing">
          <Icon name="share" size="small" />
          {t('shareApp')}
        </Menu.Item>
      </Link>
      {
        isLoggedIn
          ? (
            <Link to="/" onClick={() => { dispatch(closeMenu()); dispatch(dropToken()); }}>
              <Menu.Item name="logout">
                <Icon name="log out" size="small" />
                {t('logOut')}
              </Menu.Item>
            </Link>
          ) : null
      }
    </Sidebar>
  );
}

export default SideMenu;
