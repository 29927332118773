const translations = {
    en: {
        save: "Save",
        selectLanguage: "Select a language",
        eventTickets: "Event tickets",
        visitAsiaExpo: "Visit to ASIAEXPO 2025",
        ticketOnePerson: "Ticket for one person to attend the Sirius Arena Olympic Park Congress",
        organizer: "Organizer",
        visitor: "Visitor",
        promo: "Promo",
        media: "The media",
        speaker: "Speaker",
        exhibitor: "The Exhibitor",
        endOfList: "End of the list",
        tickets: "Tickets",
        users: "Users",
        accessRights: "Access rights",
        menu: "Menu",
        search: "Search",
        entrance: "Entrance",
        shareApp: "Share the app",
        logOut: "Log out of your account",
        ticketing: "Making an order",
        personalData: "Personal data",
        name: "Name",
        required: "Required to fill in",
        surname: "Surname",
        organizationName: "Name of the organization",
        position: "Who are you / Position",
        notSpecified: "Not specified",
        mobilePhone: "Mobile phone",
        email: "Email",
        confirmOrder: "Confirm the order",
        readyForAccreditation: "Ready for accreditation",
        moreDetailed: "More detailed",
        awaitingPayment: "Awaiting payment",
        accreditedBadgeIssued: "Accredited, badge issued",
        issueBadge: "Issue a badge",
        change: "To change",
        repeatOrder: "Repeat the order",
        orderCost: "The cost of the order",
        scanning: "Scanning",
        myLocation: "My location",
        location:"Location",
        scanBadge: "Scan the badge",
        hoverQrCode: "Hover over the QR code to read it",
        requestPermission: "Request permission",
        stopScanning: "Stop scanning",
        chooseEventLocation: "Choose an event and your location",
        cancel: "Cancel",
        startingRegistration: "Starting the registration process",
        creatingOrder: "Creating an order",
        orderCreated: "The order was successfully created",
        choosePaymentMethod: "Choose a payment method",
        promoCode: "Promo code",
        accuracy:"accuracy",
        apply: "Apply",
        accountDetailsOrQrCode: "According to the account details or QR code, the invoice will be sent after the order is created by e-mail",
        bankPagePayment: "On the bank's page with a card or using a QR code",
        onlinePayment: "Online payment",
        usedCashPayment: "Used cash payment to a bank account",
        back: "Back"
    },
    ru: {
        save: "Сохранить",
        selectLanguage: "Выберите язык",
        eventTickets: "Билеты на мероприятие",
        visitAsiaExpo: "Посещение ASIAEXPO 2025",
        ticketOnePerson: "Билет для одного человека на посещение конгресса в Олимпийском парке Сириус-арена",
        organizer: "ОРГАНИЗАТОР",
        visitor: "ПОСЕТИТЕЛЬ",
        promo: "Промо",
        media: "СМИ",
        speaker: "Спикер",
        exhibitor: "Экспонент",
        endOfList: "Конец списка",
        tickets: "Билеты",
        users: "Посетители",
        accessRights: "Доступы",
        menu: "Меню",
        search: "Поиск",
        entrance: "Вход",
        shareApp: "Поделиться приложением",
        logOut: "Выйти из учетной записи",
        ticketing: "Оформление заказа",
        personalData: "Персональные данные",
        name: "Имя",
        required: "Обязательно для заполнения",
        surname: "Фамилия",
        organizationName: "Наименование организации",
        position: "Кем являетесь / Должность",
        notSpecified: "Не задан",
        mobilePhone: "Мобильный телефон",
        email: "Электронная почта",
        confirmOrder: "Подтвердить заказ",
        readyForAccreditation: "Готов для аккредитации",
        moreDetailed: "Подробнее",
        awaitingPayment: "В ожидании оплаты",
        accreditedBadgeIssued: "Аккредитован, бейдж выдан",
        issueBadge: "Выдать бейдж",
        change: "Изменить",
        repeatOrder: "Повторить заказ",
        orderCost: "Стоимость заказа",
        scanning: "Сканирование",
        myLocation: "Моя локация",
        location:"Местоположение",
        scanBadge: "Отсканируйте бейдж",
        hoverQrCode: "Наведите на QR-код для считывания",
        requestPermission: "Запросить разрешение",
        accuracy:"Точность",
        stopScanning: "Остановить сканирование",
        chooseEventLocation: "Выберите событие и вашу локацию",
        cancel: "Отмена",
        startingRegistration: "Начинаем оформление",
        creatingOrder: "Создаем заказ",
        orderCreated: "Заказ успешно создан",
        choosePaymentMethod: "Выбрать способ оплаты",
        promoCode: "Промокод",
        apply: "Применить",
        accountDetailsOrQrCode: "по реквизитам счета или QR-кодом, счет отправим после создания заказа по эл. почте",
        bankPagePayment: "на странице банка картой или через QR-код",
        onlinePayment: "Оплата онлайн",
        usedCashPayment: "Оплата б/нал на банковский счет",
        back: "Назад"
    },
    ar: {
        save: "حفظ",
        selectLanguage: "اختر لغة",
        eventTickets: "تذاكر الحدث",
        visitAsiaExpo: "زيارة آسياكسبو 2025",
        ticketOnePerson: "تذكرة لشخص واحد لحضور مؤتمر حديقة سيريوس أرينا الأولمبية",
        organizer: "المنظم",
        visitor: "الزائر",
        promo: "شركة إعلانات",
        media: "وسائل الإعلام",
        speaker: "المتحدث",
        exhibitor: "مشارك في المعرض",
        endOfList: "نهاية القائمة",
        tickets: "التذاكر",
        users: "الزوار",
        accessRights: "مرور",
        menu: "شريط القوائم",
        search: "بحث",
        entrance: "مدخل",
        shareApp: "مشاركة التطبيق",
        logOut: "تسجيل الخروج من حسابك",
        ticketing: "تسجيل الطلب",
        personalData: "البيانات الشخصية",
        name: "الاسم",
        required: "مطلوب لملء",
        surname: "اللقب",
        organizationName: "اسم المنظمة",
        position: "من أنت / المنصب",
        notSpecified: "غير محدد",
        mobilePhone: "الهاتف المحمول",
        email: "البريد الإلكتروني",
        confirmOrder: "تأكيد الطلب",
        readyForAccreditation: "على استعداد لإصدار شارة",
        moreDetailed: "أكثر تفصيلا",
        awaitingPayment: "في انتظار الدفع",
        accreditedBadgeIssued: "معتمد ، شارة صادرة",
        issueBadge: "إصدار شارة",
        change: "لتغيير",
        repeatOrder: "تكرار الحجز",
        orderCost: "تكلفة الطلب",
        scanning: "المسح الضوئي",
        myLocation: "موقعي",
        location:"الموقع",
        scanBadge: "مسح الشارة",
        hoverQrCode: "مرر مؤشر الماوس فوق رمز الاستجابة السريعة لقراءته",
        requestPermission: "طلب الإذن",
        accuracy:"الدقة",
        stopScanning: "توقف عن المسح",
        chooseEventLocation: "اختيار الحدث والموقع الخاص بك",
        startingRegistration: "بدء التسجيل",
        cancel: "الإلغاء",
        creatingOrder: "إنشاء أمر",
        orderCreated: "تم إنشاء الطلب بنجاح",
        choosePaymentMethod: "اختر طريقة الدفع",
        promoCode: "الرمز الترويجي",
        apply: "استخدام",
        accountDetailsOrQrCode: "وفقا لتفاصيل الحساب أو رمز الاستجابة السريعة ، سوف نرسل الفاتورة عن طريق البريد الإلكتروني بعد إنشاء الطلب.",
        bankPagePayment: "على صفحة البنك عن طريق البطاقة أو باستخدام رمز الاستجابة السريعة",
        onlinePayment: "الدفع عبر الإنترنت",
        usedCashPayment: "الدفع غير النقدي إلى حساب مصرفي",
        back: "للعودة"
    },
};

export default translations;