import React, {useEffect} from 'react';
import { Header } from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';

import './styles.css';
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from '../../components/CheckoutSteps';
import { isSearchVisible } from '../../components/NavTopBar/reducer';

import {closeSearch} from "../../components/NavTopBar/actions";
import {store} from "../../configureStore";
import {getPlacedOrder} from "../Orders/reducer";
import OrderCard from "../../components/OrderCard";
import {receiveOrderData} from "../Orders/actions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function CheckoutStepConfirm(props) {

  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const placedOrder = useSelector((state) => getPlacedOrder(state.orders));
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };


  useEffect(() => {
        if (searchVisible) {
          dispatch(closeSearch());
        }

        window.scrollTo(0, 0);

      dispatch(receiveOrderData([]));
  }, []);

  console.log(placedOrder);

  return (
      <div>
          <Header textAlign="center">{t('orderCreated')}</Header>
          {/*<CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />*/}
          <OrderCard order={placedOrder} button={false}/>
      </div>
    );
  }



