import React, {useEffect} from 'react';
import {Header, Button, Dimmer, Loader} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.css';
import AddressCard from '../../components/AddressCard';
import CardSummary from '../../components/CardSummary/CardSummary';
import CheckoutSteps, { CHECKOUT_STEP_SHIPPING } from '../../components/CheckoutSteps';
import {getTotalPrice, hasOnlyVirtuals} from '../Cart/reducer';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { closeSearch } from '../../components/NavTopBar/actions';
import {getAddressFields} from "../../components/AddressCard/reducer";
import ShippingCard from "../../components/ShippingCard";
import ConfirmOrderButton from "../../components/OrderCard/ConfirmOrderButton";
import {getPlaceOrderInProgress} from "../Orders/reducer";
import {getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {checkoutOrderPlaced} from "../Orders/actions";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function CheckoutStepShipping(props) {

    const dispatch = useDispatch();
    const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
    const total_products = useSelector((state) => getTotalPrice(state.cart));
    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));
    const total_price = useSelector((state) => getTotalPrice(state.cart));
    const addressFields = useSelector((state) => getAddressFields(state.address));
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    useEffect(() => {
        if (searchVisible) {
            dispatch(closeSearch());
        }
        if (mixpanel)
            mixpanel.track('Checkout Address', { ...currentExpoLocation, ...userLocation, ...userData, ...addressFields });
        window.scrollTo(0, 0);
        dispatch(checkoutOrderPlaced({})); // сбрасываем зависшие сессии по созданию заказа
    }, []);

    const button_text = total_price ? t('choosePaymentMethod') : t('confirmOrder');

    return (
        <div>
            <Header textAlign="center">{t('ticketing')}</Header>
            {/*<CheckoutSteps step={CHECKOUT_STEP_SHIPPING} />*/}
            {<Dimmer active={ getLoading === 1 } inverted>
                <Loader inverted> {t('creatingOrder')} ...</Loader>
            </Dimmer>}
            <AddressCard />
            { !only_virtuals ? <ShippingCard /> : '' }
            {/*<CardSummary total_products={total_products} />*/}
            { addressFields.hasErrors
                ? <Button className="checkout-address-buttons-button disabled" color="green" disabled={true}>{button_text}</Button>
                : (total_price
                    ? <Link to="/checkout-step-payment" className='goto-payment-options-link'>
                        <Button className="checkout-address-buttons-button" color="green">{t('choosePaymentMethod')}</Button>
                        </Link>
                    : <ConfirmOrderButton
                        addClassName="checkout-address-buttons-button"
                        buttonText={t('confirmOrder')}
                        pathToRoute="/checkout-step-confirm"
                    />)
            }
        </div>
    );
}



