import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Card, Loader, Button, Dimmer
} from 'semantic-ui-react';
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import MiniOrderCard from "./MiniOrderCard";
import CardSummary from "../CardSummary/CardSummary";
import CartProduct from "../../views/Cart/CartProduct";
import {useNavigate} from "react-router-dom";
import OrderCardHeader from "./OrderCardHeader";
import {addProduct, removeAllProductsFromCart, setQuantity} from "../../views/Cart/actions";
import {isCountriesFetching} from "../AddressCard/reducer";
import {
    ADDRESS_COMPANY, ADDRESS_COMPANY_ADDR, ADDRESS_COMPANY_CHIEF_NAME, ADDRESS_COMPANY_CHIEF_POST, ADDRESS_COMPANY_INN,
    ADDRESS_COMPANY_JOB_TITLE, ADDRESS_COMPANY_KPP, ADDRESS_COMPANY_OGRN,
    ADDRESS_COUNTRY, ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME,
    ADDRESS_LASTNAME, ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, ADDRESS_POSTCODE, ADDRESS_STATE, setAddressFields
} from "../AddressCard/actions";
import {getUserData} from "../UserLogin/reducer";
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {getProducts} from "../../views/Home/reducer";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";


export default function OrderCard(props) {

    const {order, button = true} = props;
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const dispatch = useDispatch();
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const products = useSelector((state) => getProducts(state.products));

    const navigate = useNavigate();

    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();

    let orderLabelText = "не оформлен", labelColor = "red";
    switch (order.status) {
        case 'processing':
            orderLabelText = t('readyForAccreditation');
            labelColor = "yellow";
            break;
        case 'completed':
            orderLabelText = t('accreditedBadgeIssued');
            labelColor = "green";
            break;
        case 'on-hold':
        case 'pending':
            orderLabelText = t('awaitingPayment');
            labelColor = "red";
            break;
        case 'cancelled':
            orderLabelText = "отменен";
            labelColor = "brown";
            break;
        default:
            break;
    }

    console.log('OrderCard', order);

    function handleClick() {

        const {line_items, billing, id} = order;

        dispatch(removeAllProductsFromCart());

        line_items.forEach(item => {
            const product = products.find((obj) => obj.id === Number(item.product_id),);
            const virtual = product.virtual;
            dispatch(
                addProduct(
                    product.id,
                    product.name,
                    product.price,
                    product.src,
                    product.virtual,
                    (item.variation_id ? item.variation_id : null),
                    null,
                ),
            );

        });

        dispatch(setAddressFields(
        {
                [ADDRESS_FIRSTNAME]: billing.first_name,
                [ADDRESS_LASTNAME]: billing. last_name,

                [ADDRESS_COMPANY]: billing.company,
                [ADDRESS_COMPANY_JOB_TITLE]: billing.company_job_title,
                [ADDRESS_COMPANY_ADDR]: billing.company_law_address,
                [ADDRESS_COMPANY_INN]: billing.company_inn,
                [ADDRESS_COMPANY_KPP]: billing.company_kpp,
                [ADDRESS_COMPANY_OGRN]: billing.company_ogrn,
                [ADDRESS_COMPANY_CHIEF_POST]: billing.company_chief_post,
                [ADDRESS_COMPANY_CHIEF_NAME]: billing.company_chief_name,

                [ADDRESS_COUNTRY]: billing.country,
                [ADDRESS_STATE]: billing.state,
                [ADDRESS_PHONE]: billing.phone,
                [ADDRESS_PHONE_COUNTRY]: billing.phone_country,
                [ADDRESS_EMAIL]: billing.email,
            }
        ));

        navigate("/checkout-step-shipping");

        if (mixpanel)
            mixpanel.track('Order Again Button', { ...currentExpoLocation, ...userLocation, ...userData, id });
    }



    return (
        <div>
            <Card centered className="order-card">
                <Dimmer active={ [1, order.id].includes(getLoading) || getLoadingCountries } inverted>
                    <Loader className="order-update-loader"/>
                </Dimmer>
                <OrderCardHeader order={order} actionButtons={true}/>
                <MiniOrderCard key={order.id} order={order} header={false} circular={true} actionButtons={true} />
                {order.line_items.map((product) => (
                    <CartProduct
                        key={product.variation_id === 0 ? product.product_id : product.variation_id}
                        product={{
                            id: product.product_id,
                            variant_id: product.variant_id === 0 ? null : product.variant_id,
                            quantity: product.quantity,
                            image: product.image.src,
                            name: product.name,
                            price: String(product.price),
                            dateAdded: order.date_created,
                         }}
                        show_buttons={false}
                    />
                ))}
                <CardSummary
                    total_products={order.total}
                    total_shipping={order.shipping_total}
                    show_shipping = {order.shipping_lines.length > 0}
                />
                {button ? <Button fluid className="button-create-again" color="orange" onClick={handleClick}>
                    {t('repeatOrder')}
                </Button> : <></>}
            </Card>
        </div>
    );
}